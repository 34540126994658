import React, { Fragment, Component } from 'react';
import './good-beef-info.scss';
import GoodBeefIndexLogo from '../../util/icons/components/GoodBeefIndexLogo';
import GoodBeefShieldConcept from '../../util/figures/GoodBeefShieldConcept';
import PropTypes from 'prop-types';
import GoodBeefScale from '../../util/figures/GoodBeefScale';

class GoodBeefInfo extends Component {
  constructor (props) {
    super(props);
    this.state = {
      mountTime: null
    };
  }
  componentDidMount () {
    this.setState({ mountTime: new Date() });
  }
  render () {
    return (
      <Fragment>
        <GoodBeefIndexLogo />
        <p>The maximum GoodBeef Index value is 120 –
          as an aspirational target of excellence to aim towards</p>
        <div className="gbi-scale-wrapper">
          <GoodBeefScale score={this.props.score} />
        </div>
        <div className="divider"></div>
        <GoodBeefShieldConcept
          mountTime={ this.state.mountTime }
          score={ this.props.score }/>
      </Fragment>
    );
  }
}

GoodBeefInfo.propTypes = {
  score: PropTypes.number.isRequired
};

export default GoodBeefInfo;
